<template>
	<div>
		<div class="d-flex">
			<span class="display-1 mb-3">
				{{ user.name.full }}
			</span>
			<UserInfoForm :loading-parent="loading || loadingParent" />
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'UserInfo',
	props: {
		loadingParent: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			loading: false,
		}
	},
	components: {
		UserInfoForm: () => import('@/components/users/UserInfoForm.vue'),
	},
	computed: {
		...mapGetters({
			user: 'user/currentUser',
		}),
	},
}
</script>
